import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import fetchCall, {fetchCallText, getCall, getCallText} from "../../../Utils/FetchCall";


/**
 * Filter jobs by query
 */

function filterJobs(jobs, query){
  if (query){
    // filter by location
    jobs = query.location && query.location !== "" ? jobs.filter((job)=>job.locationId === query.location) : jobs; 
    // filter by Department
    jobs = query.department ? jobs.filter((job)=>job.departmentId === query.department) : jobs; 
    // filter by Type
    jobs = query.type ? jobs.filter((job)=>job.typeId === query.type) : jobs; 
  }
  return jobs;
}

/**
 * Custom Hook in charge of parse the 
 * query string
 */
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function useAppController(){

  const [locations, setLocations] = React.useState([]);
  const [departments, setDepartments] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [jobs, setJobs] = React.useState(null);
  const [dbJobs, setDbJobs] = React.useState([]);
  const [number, setNumber] = React.useState(3);
  const jobsPerLoad = 3;
  
  // React router Query
  const currentQuery = useQuery();

  //Query paramaters Values
  const num = currentQuery.get("num");
  const location = currentQuery.get("location");
  const department = currentQuery.get("department");
  const type = currentQuery.get("type");

  const query = {
    num: num && !isNaN(num) ? parseInt(num) : 3, 
    location:location ? location : "", 
    department:department ? department : "", 
    type: type ? type : ""
  };

  const [queryParams, setParams] = useSearchParams();

  React.useEffect(()=>{

    const apiCall = async (callBack, params)=>{

      try{

        const body = await getCall(params);

        // Handling API errors  
        if (body.error === "" ){
          if (params.action === "getJobs"){
           const filteredJobs = filterJobs(body.data, query);
           callBack(body.data);
           setJobs(filteredJobs);
          } else {
            callBack(body.data)
          }
        }else{
          console.error(body.error);
        }

      } catch(err){

        // Handling connection errors  
        console.error(err);
      }
    };

    apiCall(
      setLocations, 
      {action:"getJobLocations"},
    );

    apiCall(
      setDepartments, 
      {action:"getJobDepartments"},
    );

    apiCall(
      setTypes, 
      {action:"getJobTypes"},
    );

    apiCall(
      setDbJobs, 
      {action:"getJobs"},
    );
    
  },[]);

  // Decaler Handles

  const onChange = (type, id) =>{
    const newQuery =  {...query, [type]:id};
    setJobs(filterJobs(dbJobs, newQuery));
    setParams(newQuery);
  }

  const onClick = (e, num) => {
    e.preventDefault();
    setParams({...query, num: query.num + jobsPerLoad});
  }

  return [query, locations, departments, types, jobs, onClick, onChange];

}

export default useAppController;
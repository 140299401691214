function Job({job}){

  const country = job.CountryCode === "US" ? "usa.png" : "canada.png";
  var date1 = new Date();
  var date2 = new Date(job.OpenDate);
  var Difference_In_Time = date1.getTime() - date2.getTime();
  var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
  var datestring = "Posted "+Difference_In_Days+" Days Ago";
  if (Difference_In_Days <= 1) {
    datestring = "Posted Today";
  }

  var prefixstr = "Canlan Sports™ ";
  if (job.OfficeName == "Cwench Centre by Canlan Sports") {
    prefixstr = "";
  }

  return(
    <>
      <a href={`/career/?jobid=${job.cid}`} title={`${job.PositionTitle}`}>
      <div className="job">
        <div className='type'>{job.EmploymentType}</div>
        <div className='time'>{datestring}</div>
        <div className='title'>{job.PositionTitle}</div>
        <div className='city'>
          <img 
            className='icon faclogo' 
            src={`/themes/theme/images/icons/${country}`}
          />
          {`${job.City}, ${job.CountrySubdivisionName} - ${prefixstr}${job.OfficeName}`}
        </div>
        <div className='cta'>
          <span>
            View
          </span>
        </div>
      </div>
      </a>
    </>
  );
}


export default Job;
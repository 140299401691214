import Job from "./Job";

function Jobs({jobs, number})
{
  jobs = jobs && jobs.length > 0 && number !== 'NaN'  ? jobs.slice(0, number) : jobs;
  return (
    <>
      <div className="jobs">
        {jobs && jobs.length > 0 && jobs.map((job)=><Job job={job} />)}
        {!jobs && "Loading..."}
        {jobs && jobs.length === 0 && "No Jobs Found"}
      </div>
    </>
  );
}

export default Jobs;
async function fetchCall(params){

  const api = window.careersApi;
  // Packing the parameters 
  const query = new FormData();
  for ( let key in params ) {
    query.append(key, params[key]);
  }

  query.append('nonce', api.nonce); 
  
  const response = await fetch(api.ajaxurl,{
    method: "POST",
    body: query,
  });
  
  if (!response.ok) throw new Error(response.statusText);
  
  const body = await response.json();
  
  return body;
}

async function fetchCallText(params){
  const api = window.careersApi;

  // Packing the parameters 
  const query = new FormData();
  for ( let key in params ) {
    query.append(key, params[key]);
  }
  
  query.append('nonce', api.nonce);

  const response = await fetch(api.ajaxurl,{
    method: "POST",
    body: query
  });
  if (!response.ok) // or check for response.status
      throw new Error(response.statusText);
  const body = await response.text();
  
  return body;
}

async function getCall(params){
  const api = window.careersApi;

  // Packing the parameters 
  const query = new FormData();
  for ( let key in params ) {
    query.append(key, params[key]);
  }
  
  query.append('nonce', api.nonce);

  const queryString = new URLSearchParams(query).toString();

  const response = await fetch(`${api.ajaxurl}?${queryString}`, {
    method: "GET"
  });
  if (!response.ok) // or check for response.status
      throw new Error(response.statusText);
  const body = await response.json();
  
  return body;
}

async function getCallText(params){
  const api = window.careersApi;

  // Packing the parameters 
  const query = new FormData();
  for ( let key in params ) {
    query.append(key, params[key]);
  }
  
  query.append('nonce', api.nonce);

  const queryString = new URLSearchParams(query).toString();

  const response = await fetch(`${api.ajaxurl}?${queryString}` ,{
    method: "GET"
  });
  if (!response.ok) // or check for response.status
      throw new Error(response.statusText);
  const body = await response.text();
  
  return body;
}



export {fetchCallText, getCall, getCallText};
export default fetchCall;
import React from "react";

import fetchCall, {fetchCallText} from '../../Utils/FetchCall';
import useFilterController from "../App/Controller/AppController";

function Filter({ query, locations, departments, types, onChange}){

  const handleOnChange = (e) => {
    e.preventDefault();
    onChange(e.target.name, e.target.value);
  }

  return (
    <>
      <div className="filter">
        <div class="location">
          <select value= {query.location} defaultValue = "" name="location" onChange={(e)=>handleOnChange(e)} >
              <option value="">Select your location</option>
              {locations.map((location)=><option value={location.id} >{location.OfficeName}</option>)}
          </select>
        </div>
        <div class="department">
          <select value = {query.department} defaultValue = "" name="department" onChange={(e)=>handleOnChange(e)} >
              <option value="">Select your department</option>
              {departments.map((department)=><option value={department.id} >{department.DepartmentName}</option>)}
          </select>
        </div>
        <div class="type">
          <select value = {query.type} defaultValue = "" name="type" onChange={(e)=>handleOnChange(e)} > 
              <option value="">Select employment type</option>
              {types.map((type)=><option value={type.id}  >{type.EmploymentType}</option>)}
          </select>
        </div>
      </div>
    </>
  );
}

export default Filter;
import Filter from '../Filter';
import Jobs from '../Jobs';
import useAppController from './Controller/AppController'

function App() {

  const [
    query, 
    locations, 
    departments, 
    types, 
    jobs,
    onClick,
    onChange,
  ] = useAppController();  

  return (
    <>
      <section className="search">
        <Filter query= {query} locations={locations} departments={departments} types={types} onChange={onChange} />
        <Jobs number={query.num} jobs={jobs} />
        { jobs && jobs.length > query.num && <button class="btn btn-slide" title={`Show ${jobs.length - query.num} more`} onClick={(e)=>onClick(e, query.num)} >Show {jobs.length - query.num} more</button>} 
      </section>
    </>
  );
}

export default App;
